import { useMutation } from "@tanstack/react-query";
import axios from "../../libs/axios/axios";

export default function useGuestGenerateImage() {
  return useMutation({
    mutationFn: (data: any) => {
      return axios.post("/guest-generate-image", data);
    },
  });
}
