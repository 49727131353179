export default FrequentlyAskedQuestions;

import { Accordion, Container, Group, Stack, Text, Title } from "@mantine/core";
import React from "react";

import "@mantine/core/styles/Accordion.css";

function FrequentlyAskedQuestions() {
  return (
    <Group justify="center" align="center" py={60}>
      <Container w="100%" className="section-container" size="xl">
        <Stack justify="center" align="center">
          <Title c="#FFF" size="h2" order={2} tt="capitalize" ta="center">
            Frequently Asked Questions
          </Title>

          <Accordion mt={50} w="100%" maw={800}>
            <Accordion.Item
              key="What is ImgGen AI?"
              value={`ImgGen AI is a online AI image generator (text-to-image) and
              image enhancement tool. It is a simple and easy-to-use tool.`}
            >
              <Accordion.Control>
                <Text c="#FFF" fw={500}>
                  What is ImgGen AI?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Text>
                  ImgGen AI is a online AI image generator (text-to-image) and
                  image enhancement tool. It is a simple and easy-to-use tool.
                </Text>
              </Accordion.Panel>
            </Accordion.Item>
            {/* <Accordion.Item
              key="How many images I can generate for free?"
              value="You can generate unlimited images for free."
            >
              <Accordion.Control>
                <Text c="#FFF" fw={500}>
                  How many images I can generate for free?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Text>You can generate unlimited images for free.</Text>
              </Accordion.Panel>
            </Accordion.Item> */}
            <Accordion.Item
              key="What is the maximum image resolution or file size ImgGen AI supports?"
              value="ImgGen AI supports images up to 20MB in size and 4096 x4096 in resolution."
            >
              <Accordion.Control>
                <Text c="#FFF" fw={500}>
                  What is the maximum file size or image resolution ImgGen AI
                  supports?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Text>
                  ImgGen AI supports images up to 20MB in size and 4096 x4096 in
                  resolution.
                </Text>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item
              key="How to make an image larger without losing quality?"
              value="ImgGen's AI-powered image upscaler allows you to significantly enlarge images beyond their original dimensions while preserving or enhancing quality."
            >
              <Accordion.Control>
                <Text c="#FFF" fw={500}>
                  How to make an image larger without losing quality?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Text>
                  ImgGen's AI-powered image upscaler allows you to significantly
                  enlarge images beyond their original dimensions while
                  preserving or enhancing quality.
                </Text>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item
              key="Can I download the generated images for free?"
              value="Yes, every image generated on our platform is completely free for you to download without any watermarks, ImgGen logos or attribution."
            >
              <Accordion.Control>
                <Text c="#FFF" fw={500}>
                  Can I download the generated images for free?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Text>
                  Yes, every image generated on our platform is completely free
                  for you to download without any watermarks, ImgGen logos or
                  attribution.
                </Text>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item
              key="How to restore old photos?"
              value="ImgGen's AI-powered image restoration tool allows you to restore old photos and images."
            >
              <Accordion.Control>
                <Text c="#FFF" fw={500}>
                  How to restore old photos?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Text>
                  Upload your old, damaged photos to our AI image restoration
                  tool. Our algorithms will automatically remove scratches,
                  dust, creases, fading and other defects to enhance your old
                  photos.
                </Text>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item
              key="Are there any restrictions on the quantity of images I can enhance?"
              value="No, you can enhance as many images as you like for free."
            >
              <Accordion.Control>
                <Text c="#FFF" fw={500}>
                  Are there any restrictions on the quantity of images I can
                  enhance?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Text>
                  No, you can enhance as many images as you like for free with
                  Img Gen AI.
                </Text>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Stack>
      </Container>
    </Group>
  );
}
