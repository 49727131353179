export default HowToGenerateImage;

import {
  Card,
  Container,
  Group,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import React from "react";
import AnimatedCard from "../AnimatedCard";
import InputSVG from "../../static/icons/input.svg";
import ButtonSVG from "../../static/icons/button.svg";
import DownloadImageSVG from "../../static/icons/download-imags.webp";

import "@mantine/core/styles/Card.css";
import "@mantine/core/styles/Paper.css";

function HowToGenerateImage() {
  return (
    <Group justify="center" align="center" py={60}>
      <Container className="section-container" size="xl">
        <Stack justify="center" align="center">
          <Title c="#FFF" size="h2" order={2} tt="capitalize" ta="center">
            How to generate image from text
          </Title>

          <SimpleGrid
            w="100%"
            cols={{ base: 1, sm: 2, lg: 3 }}
            spacing={{ base: 10, sm: 20 }}
            mt={58}
          >
            <AnimatedCard>
              <div>
                <Card bg="#FFF1E0" radius="md" p={28} h="100%" mah={140}>
                  <Image src={InputSVG} />
                </Card>

                <Title c="#FFF" size={18} order={3} fw={700} mt={16}>
                  Type a prompt
                </Title>

                <Text component="p" mt={14} fw={400}>
                  Get started by typing or pasting any text prompt into the text
                  input to describe the image you want to generate. Let your
                  imagination run wild!
                </Text>
              </div>
            </AnimatedCard>

            <AnimatedCard>
              <div>
                <Card bg="#F6F5CA" radius="md" p={28} h="100%" mah={140}>
                  <Image mx="auto" w={"auto"} src={ButtonSVG} />
                </Card>
                <Title c="#FFF" size={18} order={3} fw={700} mt={16}>
                  Generate Image
                </Title>

                <Text mt={14} fw={400}>
                  Hit the "Generate Image" button and our AI will get to work
                  creating a stunning high-resolution image from your text
                  prompt.
                </Text>
              </div>
            </AnimatedCard>

            <AnimatedCard>
              <div>
                <Card bg="#F5EBEE" radius="md" p={28} h="100%" mah={140}>
                  <Image mx="auto" src={DownloadImageSVG} />
                </Card>
                <Title c="#FFF" size={18} order={3} fw={700} mt={16}>
                  Download Image
                </Title>
                <Text mt={14} fw={400}>
                  When ready, click the download button. The watermark-free
                  image is now yours to keep and use however you wish, free of
                  charge.
                </Text>
              </div>
            </AnimatedCard>
          </SimpleGrid>
        </Stack>
      </Container>
    </Group>
  );
}
