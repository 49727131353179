export default StartGeneratingToday;

import { Button, Container, Group, Stack, Text, Title } from "@mantine/core";
import React from "react";

// import "@mantine/core/styles/Group.css";
// import "@mantine/core/styles/Container.css";
// import "@mantine/core/styles/Stack.css";
// import "@mantine/core/styles/Title.css";
// import "@mantine/core/styles/Text.css";

function StartGeneratingToday() {
  return (
    <Group
      // bg="var(--mantine-color-primary-9)"
      justify="center"
      align="center"
      py={60}
    >
      <Container className="section-container" size="xl">
        <Stack justify="center" align="center">
          <Title c="#FFF" size="h2" order={2} tt="capitalize" ta="center">
            Start Generating Today
          </Title>

          <Text
            c="dimmed"
            mt={8}
            size="lg"
            maw={730}
            ta="center"
            lh="28px"
            fw={500}
          >
            Ready to turn your ideas into stunning visuals? Begin with ImgGen —
            it's easy, fast, and free. Start now to experience simple and quick
            AI image generation.
          </Text>
        </Stack>

        <Stack justify="center" align="center" mt={50}>
          <Button
            size="lg"
            component="a"
            variant="filled"
            href="#generate-image"
          >
            Generate Image
          </Button>
        </Stack>
      </Container>
    </Group>
  );
}
