export const prompts = [
  `A detailed portrait of a young woman exuding elegance and natural grace. She has a voluminous updo hairstyle with wisps of hair tenderly framing her face. Her hazel eyes are accentuated by tasteful makeup, with a few light freckles across her nose and cheeks. She wears a high-necked black top, suggesting a blend of casual refinement. The lighting should illuminate her face against a subdued background, emphasizing her calm and confident expression. High Fashion, dynamic, dramatic, elegant, High fashion Vogue cover Dramatic photography, supermodel, avant garde style`,
  `Photo of a lychee-inspired spherical chair, with a bumpy white exterior and plush interior, set against a tropical wallpaper.`,
  `a living room, bright modern Scandinavian style house, large windows, magazine photoshoot, 8k, studio lighting`,
  `close up photo of a rabbit, forest in spring, haze, halation, bloom, dramatic atmosphere, centred, rule of thirds, 200mm 1.4f macro shot`,
  `breathtaking shot of a bag, luxury product style, elegant, sophisticated, high-end, luxurious, professional, highly detailed`,
  `a cat under the snow with blue eyes, covered by snow, cinematic style, medium shot, professional photo, animal`,
  `freshly made hot floral tea in glass kettle on the table, angled shot, midday warm, Nikon D850 105mm, close-up`,
  // `autumn forest landscape, psychedelic style, vibrant colors, swirling patterns, abstract forms, surreal, trippy, colorful`,
  // `a sleek, ultra-thin, high resolution bezel-less monitor mockup, realistic, modern, detailed, vibrant colors, glossy finish, floating design, lowlight, art by peter mohrbacher and donato giancola, digital illustration, trending on Artstation, high-tech, smooth, minimalist workstation background, crisp reflection on screen, soft lighting`,
  // `extravagant high fashion show concept featuring elaborate costumes with feathered details and sparkling jewels, runway, fashion designer inspiration, style of gaultier and gianni versace, deep vibrant colors, strong directional light sources, catwalk, heavy diffusion of light, highly detailed, top trend in vogue, art by lois van baarle and loish and ross tran and rossdraws, Artstation, front row view, full scene, elegant, glamorous, intricate, sharp focus, haute couture`,
  `humanoid android, covered in white porcelain skin, blue eyes, white wispy ghost wearing ornate armour 4k realistic fantasy, insane details, ghost phantom effect, hyper detailed, photoreal, photography, cinematic lighting, hdr, hd, cinematography, realism, fine art digital, HD, Mark Molnar mystical, redshift rendering, 8k,`,
  `An image of a classical sculpture of a cracked female  face with serene, closed eyes and full red lips emerging from a cracked and peeling wall. The face should have a network of  cracks, as if made fragility of porcelain or plaster. The sculpture seems to be breaking free from the surrounding crumbling wall, which has a rough, tactile texture. The color palette should be muted, with earthy tones for the wall and a pale hue for the face, highlighted by the red lips.`,
  `A close up picture of a s very old man with long hair and a beard, by Johannes Sveinsson Kjarval, dynamic cinematic lighting, test of time, wise, portrait of a rugged warrior, color graded, Nordic`,
  `Digital art of a ghostly albino owl, dry tree branches. Background with lots of white smoke around. High definition, 8k, oil painting, detailed masterpiece. Translucent image, professional sinister concept art, by artgerm and Greg Rutkowski, an intricate, elegant and highly detailed digital painting, concept art, soft and sharp focus, illustration, in the style of Simon Stalenhag, Wayne Barlowe and Igor Kieryluk.`,
  `Apartment bedroom, room full of hobbies, one bed, warm light, plants, mirror cube table, fish tank, carpet floors, white desk, white oak dresser, plant stand with flowers`,
];
