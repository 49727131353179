export default WhyChooseImgGen;

import {
  Container,
  Group,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Title,
  rem,
} from "@mantine/core";
import React from "react";
import { IconFlame, IconFreeRights, IconPhotoAi } from "@tabler/icons-react";
import classes from "./styles/FeaturesAsymmetrical.module.css";
import AnimatedCard from "../AnimatedCard";

interface FeatureProps extends React.ComponentPropsWithoutRef<"div"> {
  icon: React.FC<any>;
  title: string;
  description: string;
}

function Feature({
  icon: Icon,
  title,
  description,
  className,
  ...others
}: FeatureProps) {
  return (
    <div className={classes.feature} {...others}>
      <div className={classes.overlay} />

      <div className={classes.content}>
        <Icon
          style={{ width: rem(38), height: rem(38) }}
          className={classes.icon}
          stroke={1.5}
        />
        <Text
          c="#FFF"
          fw={700}
          fz="lg"
          mb="xs"
          mt={5}
          className={classes.title}
        >
          {title}
        </Text>
        <Text fz="sm">{description}</Text>
      </div>
    </div>
  );
}

const features = [
  {
    icon: IconFlame,
    title: "Fast",
    description:
      "ImgGen uses advanced AI to generate your images in seconds. No more waiting around! Get high-quality visuals super fast.",
  },
  {
    icon: IconFreeRights,
    title: "Free",
    description:
      "Use our text-to-image generator for free. No credit cards required. Free to create watermark-free images.",
  },
  {
    icon: IconPhotoAi,
    title: "High Quality Images",
    description:
      "ImgGen generate stunning high resolution images suitable for posters, wallpapers, occasion cards, branding visuals, social posts and beyond!",
  },
];

function WhyChooseImgGen() {
  return (
    <Group justify="center" align="center" py={60}>
      <Container className="section-container" size="xl">
        <AnimatedCard>
          <Stack justify="center" align="center">
            <Title c="#FFF" size="h2" order={2} tt="capitalize" ta="center">
              Why Choose ImgGen?
            </Title>

            <SimpleGrid cols={{ base: 1, sm: 3 }} spacing={50} mt={24}>
              {features.map((item) => (
                <Feature {...item} key={item.title} />
              ))}
            </SimpleGrid>
          </Stack>
        </AnimatedCard>
      </Container>
    </Group>
  );
}
