export default Showcase;

import {
  Card,
  Container,
  Group,
  Image,
  SimpleGrid,
  Stack,
  Title,
  px,
  useMantineTheme,
} from "@mantine/core";
import React from "react";

import ResultImage1 from "../../static/results/2845be76-f157-40d5-83b4-7b9203bd15c1.webp";
import ResultImage2 from "../../static/results/2b6cb3ab-64ec-4b57-a2d6-56c3477cf587.webp";
import ResultImage3 from "../../static/results/6fb1c839-becc-4910-a3bb-c1d238a24240.webp";
import ResultImage4 from "../../static/results/774c945b-f027-4eed-9767-6dc4e4190719.webp";
import ResultImage6 from "../../static/results/WYKi57BVo13P-s.webp";
import ResultImage7 from "../../static/results/FEtqAfmPsJd-k.webp";

import Image1 from "../../static/showcase/1b02ae78-70c3-4d0b-b3b7-fafc62c6876a.webp";
import Image3 from "../../static/showcase/46f2297a-ad4c-4260-9abf-772c6c08020d.webp";
import Image4 from "../../static/showcase/575dbd15-ebd5-49c7-9e11-b5ede7738c1f.webp";
import Image5 from "../../static/showcase/5a8bd92a-b6ec-4844-a7e8-cd7b0a3e44a0.webp";
import Image7 from "../../static/showcase/b5b92e07-7aa7-416e-88af-df359cb3659c.webp";
import Image8 from "../../static/showcase/f5ecaa2d-c3b3-4dbc-9620-1e949663c6c4.webp";
// import Image9 from "../../static/showcase/f6292294-1c9e-465b-8620-350604bea7ce.jpeg";
// import Image10 from "../../static/showcase/ff0b3d84-32f9-48fe-b13f-d33247335b0c.jpeg";
import { ImageZoom } from "../ImageZoom";

const getChild = (height: number, image: string) => (
  <ImageZoom radius="md" h={height} fit="cover" src={image} />
);

const BASE_HEIGHT = 560;
const getSubHeight = (children: number, spacing: number) =>
  BASE_HEIGHT / children - spacing * ((children - 1) / children);

function Showcase() {
  const theme = useMantineTheme();

  return (
    <Group justify="center" align="center" py={60}>
      <Container className="section-container" size="xl">
        <Stack justify="center" align="center" gap={2}>
          <Title
            c="#FFF"
            size="h2"
            order={2}
            tt="capitalize"
            ta="center"
            mb={60}
          >
            Showcase
          </Title>

          <SimpleGrid cols={{ base: 1, xs: 4 }}>
            <Stack mah="max-content">
              {getChild(
                getSubHeight(2, px(theme.spacing.md) as number),
                Image1
              )}
              {getChild(
                getSubHeight(2, px(theme.spacing.md) as number),
                Image3
              )}
            </Stack>
            {getChild(BASE_HEIGHT, Image4)}
            <Stack mah="max-content" style={{ flexGrow: 1 }}>
              {getChild(
                getSubHeight(2, px(theme.spacing.md) as number),
                Image5
              )}
              {getChild(
                getSubHeight(2, px(theme.spacing.md) as number),
                Image7
              )}
            </Stack>
            {getChild(BASE_HEIGHT, Image8)}
          </SimpleGrid>

          <SimpleGrid cols={{ base: 1, xs: 4 }}>
            {getChild(BASE_HEIGHT, ResultImage1)}
            <Stack mah="max-content">
              {getChild(
                getSubHeight(2, px(theme.spacing.md) as number),
                ResultImage4
              )}
              {getChild(
                getSubHeight(2, px(theme.spacing.md) as number),
                ResultImage3
              )}
            </Stack>
            {getChild(BASE_HEIGHT, ResultImage7)}
            <Stack mah="max-content" style={{ flexGrow: 1 }}>
              {getChild(
                getSubHeight(2, px(theme.spacing.md) as number),
                ResultImage2
              )}
              {getChild(
                getSubHeight(2, px(theme.spacing.md) as number),
                ResultImage6
              )}
            </Stack>
          </SimpleGrid>
        </Stack>
      </Container>
    </Group>
  );
}
